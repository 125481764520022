<template>
  <section>
    <h2>About</h2>
    <p>Bülent Büyükkahraman graduated from Istanbul Technical University's Department of Electronics and Communication Engineering in 1996. Throughout his career, he has successfully held various cybersecurity roles ranging from engineering to directorship at international companies such as IBM, Nokia, Coca-Cola, and leading Turkish companies such as Anadolu Group, Turkcell, and Netaş. He established the Axoss Cybersecurity Academy, contributing to the development of the workforce in the cybersecurity industry. Since 2022, he has been continuing his work as the Cybersecurity Director of Anadolu Group.</p>
  </section>
</template>

<script>
export default {
  name: 'AboutSection'
}
</script>

<style scoped>
/* Add styles specific to the About component */
</style>
